import InfoCoupon from '../coupons/info-coupon';
import { AbstractInfoEnum } from './abstract-info-enum';
import { InfoCategory } from './info-category';

export enum CategoriesEnum {
  PERSIANAS = 'PERSIANAS',
  PRODUCTS_ON_OFFER = 'products_on_offer',
  PRODUCTS_ON_OFFER_PT = 'PRODUTOS_EM_OFERTA',
  AMBIENTES = 'AMBIENTES',
  COLECOES = 'COLECOES',
}

export enum SubCategoriesEnum {
  //Persianas
  ACESSORIOS_PERSIANAS = 'ACESSORIOS_PERSIANAS',
  DOUBLE_VISION = 'DOUBLE_VISION',
  HORIZONTAL = 'HORIZONTAL',
  PAINEL = 'PAINEL',
  PRONTA_ENTREGA = 'PRONTA_ENTREGA',
  ROLO = 'ROLO',
  ROMANA = 'ROMANA',
  ROMANA_DE_TETO = 'ROMANA_DE_TETO',

  //AMBIENTES
  PARA_SALA = 'PARA_SALA',
  PARA_COZINHA = 'PARA_COZINHA',
  PARA_QUARTO = 'PARA_QUARTO',
  PARA_ESCRITORIO = 'PARA_ESCRITORIO',
  PARA_BANHEIRO = 'PARA_BANHEIRO',
  PARA_VARANDA_SACADA = 'PARA_VARANDA_SACADA',
  PARA_CORPORATIVO = 'PARA_CORPORATIVO',

  //Decoracao
  PORTA_RETRATO = 'PORTA_RETRATO',
}

export enum PersianasModelsEnum {
  BLACKOUT = 'BLACKOUT',
  SEMI_BLACKOUT = 'SEMI_BLACKOUT',
  TELA_SOLAR = 'TELA_SOLAR',
  PINPOINT = 'PINPOINT',
  TEXTURIZADO = 'TEXTURIZADO',
  TRANSLUCIDO = 'TRANSLUCIDO',
  _25_MM = '_25_MM',
  _50_MM = '_50_MM',
}

export enum AttributeEnum {
  ACTUATION_SIDES = 'actuationSides',
  ACTUATION_TYPES = 'actuationTypes',
  ALUMINUM_WALL_THICKNESS = 'aluminumWallThickness',
  BRANDS = 'brands',
  CAPABILITIES = 'capabilities',
  COLORS = 'colors',
  COLORS_CODE = 'colorsCode',
  FABRIC_COMPOSITIONS = 'fabricCompositions',
  FINISHES = 'finishes',
  HEIGHT_THICKNESS = 'heightThickness',
  LENGTHS = 'lengths',
  MODELS = 'models',
  POTENCIES = 'potencies',
  SPEEDS = 'speeds',
  UNITS_COMMERCIALIZE = 'unitsCommercialize',
  VOLTAGES = 'voltages',
  WIDTHS = 'widths',
}

export enum FilterSubCategoriesPersianasEnum {
  DOUBLE_VISION = SubCategoriesEnum.DOUBLE_VISION,
  HORIZONTAL = SubCategoriesEnum.HORIZONTAL,
  PAINEL = SubCategoriesEnum.PAINEL,
  PRONTA_ENTREGA = SubCategoriesEnum.PRONTA_ENTREGA,
  ROLO = SubCategoriesEnum.ROLO,
  ROMANA = SubCategoriesEnum.ROMANA,
  ROMANA_DE_TETO = SubCategoriesEnum.ROMANA_DE_TETO,
}

export enum FilterSubCategoriesDecoracaoEnum {
  PORTA_RETRATO = SubCategoriesEnum.PORTA_RETRATO,
}

export enum GroupingAttributesEnum {
  GROUP_BY_DIMENSIONS = 'GROUP_BY_DIMENSIONS',
  GROUP_BY_COLOR = 'GROUP_BY_COLOR',
}

export enum BlindsOptionsEnum {
  CONTROL_SIDE_LEFT = 'SIDE_LEFT',
  CONTROL_SIDE_RIGHT = 'SIDE_RIGHT',
  DRIVE_MANUAL = 'DRIVE_MANUAL',
  DRIVE_MOTOR = 'DRIVE_MOTOR',
  TYPE_FIXING_CEILING = 'FIXING_CEILING',
  TYPE_FIXING_WALL = 'FIXING_WALL',
  WITH_PANEL = 'WITH_PANEL',
  WITHOUT_PANEL = 'WITHOUT_PANEL',
}

export class InfoEnum {
  categories?: Array<InfoCategory>;
  orderStatus?: Array<AbstractInfoEnum>;
  productOrigins?: Array<AbstractInfoEnum>;
  marketplaces?: Array<AbstractInfoEnum>;
  groupingAttributes?: Array<AbstractInfoEnum>;
  coupon?: InfoCoupon;

  fromJson(enumeretionsObj: any) {
    if (
      enumeretionsObj?.categories != null &&
      enumeretionsObj?.categories.length > 0
    ) {
      this.categories = [];
      let newCategory: InfoCategory;
      enumeretionsObj?.categories.forEach((item: any) => {
        newCategory = new InfoCategory();
        newCategory.fromJson(item);
        this.categories?.push(newCategory);
      });
    }
    if (
      enumeretionsObj?.orderStatus != null &&
      enumeretionsObj?.orderStatus.length > 0
    ) {
      this.orderStatus = [];
      let newOrderStatus: AbstractInfoEnum;
      enumeretionsObj?.orderStatus.forEach((item: any) => {
        newOrderStatus = new AbstractInfoEnum();
        newOrderStatus.fromJson(item);
        this.orderStatus?.push(newOrderStatus);
      });
    }
    if (
      enumeretionsObj?.productOrigins != null &&
      enumeretionsObj?.productOrigins.length > 0
    ) {
      this.productOrigins = [];
      let newProductOrigins: AbstractInfoEnum;
      enumeretionsObj?.productOrigins.forEach((item: any) => {
        newProductOrigins = new AbstractInfoEnum();
        newProductOrigins.fromJson(item);
        this.productOrigins?.push(newProductOrigins);
      });
    }
    if (
      enumeretionsObj?.marketplaces != null &&
      enumeretionsObj?.marketplaces.length > 0
    ) {
      this.marketplaces = [];
      let newMarketplace: AbstractInfoEnum;
      enumeretionsObj?.marketplaces.forEach((item: any) => {
        newMarketplace = new AbstractInfoEnum();
        newMarketplace.fromJson(item);
        this.marketplaces?.push(newMarketplace);
      });
    }

    if (
      enumeretionsObj?.groupingAttributes != null &&
      enumeretionsObj?.groupingAttributes.length > 0
    ) {
      this.groupingAttributes = [];
      let newGroupingAttributes: AbstractInfoEnum;
      enumeretionsObj?.groupingAttributes.forEach((item: any) => {
        newGroupingAttributes = new AbstractInfoEnum();
        newGroupingAttributes.fromJson(item);
        this.groupingAttributes?.push(newGroupingAttributes);
      });
    }

    if (enumeretionsObj?.coupon != null) {
      this.coupon = new InfoCoupon();
      this.coupon.fromJson(enumeretionsObj?.coupon);
    }
  }
}
