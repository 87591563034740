import { AbstractInfoEnum } from './abstract-info-enum';
import {
  CategoriesEnum,
  PersianasModelsEnum,
  SubCategoriesEnum,
} from './info-enum';
import InfoSubCategory from './info-sub-category';

export class InfoCategory extends AbstractInfoEnum {
  subCategories?: Array<InfoSubCategory>;

  override fromJson(categoriesObj: any) {
    if (!categoriesObj) return;

    super.fromJson(categoriesObj);
    this.path = this.getFullpath(categoriesObj, null);

    if (categoriesObj.subCategories?.length > 0) {
      this.subCategories = categoriesObj.subCategories.map((item: any) => {
        item.main = categoriesObj.id;
        const subCategory = new InfoSubCategory();
        subCategory.fromJson(item);
        subCategory.path = this.getFullpath(categoriesObj, subCategory);
        return subCategory;
      });

      if (this.id === CategoriesEnum.PERSIANAS) {
        this.subCategories = this.subCategories || [];
        this.subCategories?.unshift(this.createCategoryAmbientes(this));
        this.subCategories?.push(this.createCategoryCollection(this));
      }
    }
  }

  private createCategoryCollection(persianas: InfoCategory): InfoSubCategory {
    const sub = persianas.subCategories?.find(
      (c) => c.id === SubCategoriesEnum.ROLO
    );
    const newSubCategory = new InfoSubCategory();
    newSubCategory.id = CategoriesEnum.COLECOES;
    newSubCategory.description = 'Coleções';
    newSubCategory.hasActiveProduct = true;
    newSubCategory.models = sub?.collections || [];

    return newSubCategory;
  }

  private createCategoryAmbientes(persianas: InfoCategory): InfoSubCategory {
    const subCategoriesMap = this.getSubCategoriesMap(persianas);

    const newSubCategory = new InfoSubCategory();
    newSubCategory.id = CategoriesEnum.AMBIENTES;
    newSubCategory.description = 'Ambientes';
    newSubCategory.hasActiveProduct = true;
    newSubCategory.models = this.createModelsForAmbientes(subCategoriesMap);

    return newSubCategory;
  }

  private getSubCategoriesMap(persianas: InfoCategory) {
    return {
      rolo: persianas.subCategories?.find(
        (c) => c.id === SubCategoriesEnum.ROLO
      ),
      double: persianas.subCategories?.find(
        (c) => c.id === SubCategoriesEnum.DOUBLE_VISION
      ),
      romana: persianas.subCategories?.find(
        (c) => c.id === SubCategoriesEnum.ROMANA
      ),
      horizontal: persianas.subCategories?.find(
        (c) => c.id === SubCategoriesEnum.HORIZONTAL
      ),
    };
  }

  private createModelsForAmbientes(subCategoriesMap: any): AbstractInfoEnum[] {
    const modelsConfig = [
      {
        id: SubCategoriesEnum.PARA_SALA,
        description: 'Persianas para sua Sala',
        filterSubCategories: [
          subCategoriesMap.rolo?.id,
          subCategoriesMap.double?.id,
          subCategoriesMap.romana?.id,
        ],
        filterModels: [
          PersianasModelsEnum.BLACKOUT,
          PersianasModelsEnum.SEMI_BLACKOUT,
        ],
      },
      {
        id: SubCategoriesEnum.PARA_QUARTO,
        description: 'Persianas para seu Quarto',
        filterSubCategories: [
          subCategoriesMap.rolo?.id,
          subCategoriesMap.double?.id,
          subCategoriesMap.romana?.id,
        ],
        filterModels: [
          PersianasModelsEnum.BLACKOUT,
          PersianasModelsEnum.SEMI_BLACKOUT,
        ],
      },
      {
        id: SubCategoriesEnum.PARA_COZINHA,
        description: 'Persianas para sua Cozinha',
        filterSubCategories: [
          subCategoriesMap.rolo?.id,
          subCategoriesMap.horizontal?.id,
        ],
        filterModels: [
          PersianasModelsEnum.TELA_SOLAR,
          PersianasModelsEnum._25_MM,
        ],
      },
      {
        id: SubCategoriesEnum.PARA_ESCRITORIO,
        description: 'Persianas para seu Escritório',
        filterSubCategories: [
          subCategoriesMap.rolo?.id,
          subCategoriesMap.double?.id,
          subCategoriesMap.horizontal?.id,
        ],
        filterModels: [
          PersianasModelsEnum.TELA_SOLAR,
          PersianasModelsEnum._50_MM,
          PersianasModelsEnum.TRANSLUCIDO,
        ],
      },
      {
        id: SubCategoriesEnum.PARA_BANHEIRO,
        description: 'Persianas para seu Banheiro',
        filterSubCategories: [
          subCategoriesMap.rolo?.id,
          subCategoriesMap.horizontal?.id,
        ],
        filterModels: [
          PersianasModelsEnum.PINPOINT,
          PersianasModelsEnum._25_MM,
        ],
      },
      {
        id: SubCategoriesEnum.PARA_VARANDA_SACADA,
        description: 'Persianas para sua Varanda/Sacada',
        filterSubCategories: [
          subCategoriesMap.rolo?.id,
          subCategoriesMap.romana?.id,
        ],
        filterModels: [
          PersianasModelsEnum.TELA_SOLAR,
          PersianasModelsEnum.TRANSLUCIDO,
        ],
      },
      {
        id: SubCategoriesEnum.PARA_CORPORATIVO,
        description: 'Persianas para Espaço Corporativo',
        filterSubCategories: [
          subCategoriesMap.rolo?.id,
          subCategoriesMap.horizontal?.id,
          subCategoriesMap.romana?.id,
        ],
        filterModels: [
          PersianasModelsEnum.TELA_SOLAR,
          PersianasModelsEnum._50_MM,
          PersianasModelsEnum.TRANSLUCIDO,
        ],
      },
    ];

    return modelsConfig.map((config) => this.createModel(config));
  }

  private createModel(config: any): AbstractInfoEnum {
    const model = new AbstractInfoEnum();
    model.id = config.id;
    model.description = config.description;
    model.hasActiveProduct = true;
    model.path = `ambientes/${model.id?.replaceAll('_', '-')?.toLowerCase()}`;
    model.filterSubCategories = config.filterSubCategories.filter(Boolean);
    model.filterModels = config.filterModels;

    return model;
  }

  public getFullpath(category: any, subcategory: any | null): string {
    const categoryPath = category?.id?.toLowerCase()?.replaceAll('_', '-');
    const subcategoryPath = subcategory?.id
      ?.toLowerCase()
      ?.replaceAll('_', '-');
    return subcategory ? `${categoryPath}/${subcategoryPath}` : categoryPath;
  }
}
