<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<div class="order-main">
  <div class="order-main-timeline-desktop">
    <p-timeline
      *ngIf="!isCanceled() && !isMobile"
      [value]="statuses"
      layout="horizontal"
      align="bottom"
      styleClass="customized-timeline"
    >
      <ng-template pTemplate="marker" let-status>
        <i *ngIf="!status?.date" class="pi pi-circle"></i>
        <i *ngIf="status?.date" class="pi pi-circle-fill"></i>
      </ng-template>
      <ng-template pTemplate="content" let-status>
        <small class="p-text-secondary">{{ status?.title }}</small>
      </ng-template>
      <ng-template pTemplate="opposite" let-status>
        <p>{{ status?.date }}</p>
      </ng-template>
    </p-timeline>
  </div>

  <div class="order-main-timeline-mobile">
    <p-timeline *ngIf="!isCanceled() && isMobile" [value]="statuses">
      <ng-template pTemplate="marker" let-status>
        <i *ngIf="!status?.date" class="pi pi-circle"></i>
        <i *ngIf="status?.date" class="pi pi-circle-fill"></i>
      </ng-template>
      <ng-template pTemplate="content" let-status>
        <small class="p-text-secondary">{{ status?.date }}</small>
      </ng-template>
      <ng-template pTemplate="opposite" let-status>
        {{ status?.title }}
      </ng-template>
    </p-timeline>
  </div>

  <div class="order-main-canceled" *ngIf="isCanceled()">
    <p-tag
      severity="danger"
      value="PEDIDO CANCELADO {{ _order?.statusCanceled || '' }}"
    ></p-tag>
  </div>

  <div class="order-main-products" *ngIf="!isMobile">
    <p-table [value]="_order?.cartProducts || []">
      <ng-template pTemplate="header">
        <tr>
          <th>PRODUTO</th>
          <th>PREÇO</th>
          <th>QUANTIDADE</th>
          <th>SUBTOTAL</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cartProduct>
        <tr>
          <td>
            <a href="{{ cartProduct.product.url }}" target="_blank">
              <div>
                {{ cartProduct.product.name }}
                {{
                  cartProduct?.product?.productPerSquareMeter
                    ? " - (" +
                      cartProduct?.customWidth?.toFixed(2) +
                      " m x " +
                      cartProduct?.customHeight?.toFixed(2) +
                      " m)"
                    : ""
                }}
                <small
                  ><span>Marca: </span
                  >{{ cartProduct?.product?.brandDescription || "" }} -
                  <span>SKU: </span>{{ cartProduct?.product?.id }}
                </small>
                <small
                  *ngIf="cartProduct?.product?.isPersiana()"
                  class="is-blinds"
                  ><span>Comando: </span>{{ cartProduct?.controlSideDescription
                  }}<span> - Acionamento: </span
                  >{{ cartProduct?.driveDescription }}</small
                >

                <small
                  *ngIf="cartProduct?.product?.isPersiana()"
                  class="is-blinds"
                >
                  <span *ngIf="!cartProduct?.product?.isPersianaRomana()">
                    - Acabamento: </span
                  >{{
                    cartProduct?.product?.isPersianaRomana()
                      ? ""
                      : cartProduct?.panelDescription
                  }}
                </small>
              </div>
              <img
                [src]="cartProduct?.product?.coverPhoto"
                [alt]="cartProduct?.product?.name"
                width="70"
                height="70"
              />
            </a>
          </td>
          <td>
            <p>
              {{ cartProduct?.value | customCurrency }}
            </p>
          </td>
          <td>
            <p>{{ cartProduct.amount }}</p>
          </td>
          <td>
            <p>
              {{ cartProduct?.value * cartProduct?.amount | customCurrency }}
            </p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="order-main-products-mobile" *ngIf="isMobile">
    <h2>PRODUTOS</h2>
    <p-card *ngFor="let cartProduct of _order?.cartProducts || []">
      <div class="products-mobile-item">
        <img
          [src]="cartProduct?.product?.coverPhoto || ''"
          [alt]="cartProduct?.product?.name"
          width="70"
          height="70"
        />
        <p>
          {{ cartProduct?.product?.name }} - {{ cartProduct?.product?.id }}
          {{
            cartProduct?.product?.productPerSquareMeter
              ? " - (" +
                cartProduct?.customWidth?.toFixed(2) +
                " m x " +
                cartProduct?.customHeight?.toFixed(2) +
                " m) - "
              : ""
          }}
          <span *ngIf="!cartProduct?.product?.isPersiana()">
            -
            {{ cartProduct?.product?.brandDescription }}
          </span>
          <small *ngIf="cartProduct?.product?.isPersiana()">
            | Comando:
            <span>{{ cartProduct?.controlSideDescription }}</span>
            | Acionamento:
            <span>{{ cartProduct?.driveDescription }}</span>
            <small *ngIf="!cartProduct?.product?.isPersianaRomana()">
              | Acabamento: </small
            ><span>{{ cartProduct?.panelDescription }}</span>
          </small>
        </p>
      </div>
      <div class="products-mobile-item">
        <div>
          <p>
            Preço:
            <span>{{ cartProduct?.product?.value | customCurrency }}</span>
          </p>
          <p>
            Qtd: <span>{{ cartProduct.amount }}</span>
          </p>
          <p>
            Subtotal:
            <span>{{
              (cartProduct?.product?.value || 0) * (cartProduct?.amount || 1)
                | customCurrency
            }}</span>
          </p>
        </div>
      </div>
    </p-card>
  </div>

  <div class="order-main-cards">
    <div class="order-main-cards-item">
      <h2>{{ user?.isAdmin ? "DADOS DO CLIENTE" : "MEUS DADOS" }}</h2>
      <p-card>
        <p>{{ _order?.orderBuyer?.name || "" }}</p>
        <p>
          Cpf: <span>{{ _order?.orderBuyer?.document || "" }}</span>
        </p>
        <p>{{ _order?.orderBuyer?.email || "" }}</p>
        <p>
          Fone:
          <span
            >{{ _order?.orderBuyer?.areaCode || "" }} -
            {{ _order?.orderBuyer?.phone || "" }}</span
          >
        </p>
        <p>
          Rua:
          <span
            >{{ _order?.address?.address || "" }} -
            {{ _order?.address?.numberAddress }} -
            {{ _order?.address?.complement || "" }} -
            {{ _order?.address?.district || "" }} -
            {{ _order?.address?.city || "" }} -
            {{ _order?.address?.uf || "" }} -
            {{ _order?.address?.zipCode || "" }}</span
          >
        </p>
      </p-card>
    </div>

    <div class="order-main-cards-item">
      <h2>DADOS DA ENTREGA</h2>
      <p-card *ngIf="_order?.address && _order?.nameUser">
        <p>
          {{ storePickUp() ? "Opção: " : "Transportadora: "
          }}<span>{{ shippingCompany.company }}</span>
        </p>
        <p *ngIf="storePickUp()">
          R. Pedro Bunn, n° 1603 - Jd. Cidade de Florianopolis, São José - SC,
          88111-120.
        </p>
        <p *ngIf="storePickUp()">
          Horário: <span>Segunda à Sexta - 8h00 às 11:30 e 13:30 às 18:00</span>
        </p>

        <p *ngIf="!storePickUp() && shippingCompany?.dtCollect">
          Coleta:
          <span>{{ shippingCompany.dtCollect }}</span>
        </p>
        <p *ngIf="storePickUp()">
          Liberado: <span>{{ shippingCompany.dtEstimate }}</span>
        </p>
        <p>
          Qtd volumes: <span>{{ _order?.totalVolumes }}</span>
        </p>
        <p>
          Peso: <span>{{ _order?.totalWeight + " kg" }}</span>
        </p>
        <p *ngIf="_order?.dtEstimate">
          Previsão: <span>{{ _order?.dtEstimate }}</span>
        </p>
        <p *ngIf="!storePickUp() && shippingCompany?.tracking">
          Rastreio: <span>{{ shippingCompany.tracking }}</span>
        </p>
        <p *ngIf="shippingCompany?.url && !storePickUp()">
          <a href="{{ shippingCompany.url }}" target="_blank">
            {{ shippingCompany.url }}</a
          >
        </p>
        <ng-template *ngIf="storePickUp()" pTemplate="footer">
          <div>
            <button
              class="button-rm"
              aria-label="Abrir mapa da loja"
              title="Abrir mapa da loja"
              (click)="openMapsRM()"
            >
              COMO CHEGAR
            </button>
          </div>
          <div *ngIf="canSendTrackingDelivery()">
            <button (click)="trackingDelivery = {}">Preencher Dados</button>
          </div></ng-template
        >
      </p-card>
    </div>

    <div class="order-main-cards-item">
      <h2>DADOS DO PAGAMENTO</h2>
      <p-card>
        <p>
          Pagamento: <span>{{ paymentData.form }}</span>
        </p>
        <p *ngIf="paymentData.qtdInstallments">
          Parcelas: <span>{{ paymentData.qtdInstallments }}</span>
        </p>
        <p *ngIf="_order?.orderEntry !== 'rm'">
          ID MKT:
          <span>{{ paymentData.orderNumber || "" }}</span>
        </p>
        <p *ngIf="_order?.orderEntry !== 'rm'">
          ID HUB:
          <span>{{ _order?.orderHubId || "" }}</span>
        </p>
        <p *ngIf="_order?.orderTransaction">
          Status: <span>{{ paymentData.status }}</span>
        </p>
        <div *ngIf="productHaveVolume()">
          <p>Volumes</p>
          <div *ngFor="let cp of _order?.cartProducts">
            <div>
              <p>
                Produto: <span>{{ cp?.product?.id }}</span>
              </p>
              <p *ngFor="let vol of cp?.product?.volumes">
                A=<span
                  >{{ vol.packingHeightThickness }}cm x L={{
                    vol.packingWidth
                  }}cm x C= {{ vol.packingLength }}cm</span
                >
              </p>
            </div>
          </div>
        </div>
        <div
          *ngIf="isPending() && _order?.orderTransaction?.pix"
          class="order-main-countdown"
        >
          <p class="order-main-countdown-title">
            <span> O pedido será cancelado em:</span>
          </p>
          <app-countdown-timer
            [creationTime]="_order?.createdAt || ''"
          ></app-countdown-timer>
        </div>

        <ng-template pTemplate="footer">
          <div *ngIf="isPending() && _order?.orderTransaction?.pix">
            <button
              class="button-rm"
              aria-label="Abrir opções de pagamento via pix"
              title="Abrir opções de pagamento via pix"
              (click)="onClickPayment()"
            >
              REALIZAR PAGAMENTO
            </button>
          </div>
          <div *ngIf="hasNFE()">
            <button
              class="button-rm"
              aria-label="Fazer download da Nota Fiscal"
              title="Fazer download da Nota Fiscal"
              (click)="downloadNFE()"
            >
              NOTA FISCAL
            </button>
          </div>
        </ng-template>
        <button
          *ngIf="isCanceled()"
          class="button-rm"
          type="button"
          pButton
          pRipple
          aria-label="Gerar pedido novamente!"
          (click)="generateOtherOrder()"
        >
          GERAR PEDIDO NOVAMENTE
        </button>
      </p-card>
    </div>
    <div class="order-main-cards-item order-main-values">
      <h2>VALORES</h2>
      <p-card>
        <p>
          Subtotal <span>{{ getSubtotal() || 0 | customCurrency }}</span>
        </p>

        <p *ngIf="_order?.freight">
          Frete <span>{{ _order?.freight || 0 | customCurrency }}</span>
        </p>

        <p *ngIf="_order?.interestValue">
          Juros <span>{{ _order?.interestValue || 0 | customCurrency }}</span>
        </p>

        <p *ngIf="_order?.coupon?.code" class="discount">
          Cupom <span>{{ _order?.coupon?.code }}</span>
        </p>

        <p *ngIf="_order?.discount" class="discount">
          Desconto <span>{{ getDiscount() || 0 | customCurrency }}</span>
        </p>

        <p>
          Total <span>{{ getOrderValue() || 0 | customCurrency }}</span>
        </p>
      </p-card>
    </div>
  </div>
</div>
<app-modal-pix
  [order]="_order"
  [pix]="pix"
  [visible]="showModalPix"
  [showCountDown]="false"
  (closeModal)="showModalPix = false"
  (paymentAccept)="pixPaymentAccept()"
></app-modal-pix>
