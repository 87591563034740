<div class="products-infos-zipcode">
  <div class="products-infos-zipcode-header">
    <p>Quantidade</p>
    <p>Seu CEP</p>
  </div>
  <div class="products-infos-zipcode-content">
    <input
      class="products-infos-zipcode-quantity"
      pInputText
      type="number"
      [value]="quantitySelected"
      (change)="setQuantitySelected($event)"
      name="quantitySelected"
      aria-label="Alterar quantidade do produto"
      [min]="0"
      [max]="100"
    />

    <app-zip-code-input
      placeholder="Seu CEP"
      (changed)="changeZipCode()"
      (zipcodeError)="zipCodeError()"
      (addressFound)="addressFound($event)"
      [showKnowZipCoded]="false"
      [emitterEventError]="true"
    ></app-zip-code-input>

    <p-progressSpinner
      strokeWidth="3"
      *ngIf="loadingFreight"
      aria-label="Buscando informações de frete"
    ></p-progressSpinner>

    <div
      class="products-infos-zipcode-content-quote"
      *ngIf="quotes?.carriers?.length || 0 > 0"
    >
      <p>
        <span>{{ getCarrier(1).displayName?.toLowerCase() }}</span> -
        {{ getCarrier(1).deliveryTime }}
        {{ getCarrier(1).deliveryTime === 1 ? "dia" + " útil" : "dias úteis" }}
        - {{ getCarrier(1).priceFreight | customCurrency }}
      </p>
      <p>
        ou retire na loja em {{ getCarrier(0).deliveryTime }}
        {{ getCarrier(0).deliveryTime === 1 ? "dia" + " útil" : "dias úteis" }}
        sem custos!
      </p>
    </div>
  </div>
</div>
