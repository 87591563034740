export default class ProductFilter {
  active?: boolean;
  aluminumWallThickness?: string;
  brand?: string;
  categories?: string[];
  capacity?: string;
  color?: string;
  collection?: string;
  direction?: string;
  discount?: number;
  inactive?: boolean;
  featured?: boolean;
  finish?: string;
  filterExcluded?: string;
  heightThickness?: string;
  ignoreMainVariation?: boolean;
  length?: string;
  model?: string;
  models?: string[];
  name?: string;
  order?: any;
  perPage?: number;
  page?: number;
  productPerSquareMeter?: boolean;
  potency?: string;
  querySearch?: string;
  speed?: string;
  subCategories?: string[];
  voltage?: string;
  width?: string;
  withDiscountOrFeatured?: boolean;

  public setValue(attribute: string, value: string) {
    switch (attribute) {
      case 'aluminumWallThickness':
        if (value) {
          this.aluminumWallThickness = value;
        } else {
          delete this.aluminumWallThickness;
        }
        break;
      case 'brand':
        if (value) {
          this.brand = value;
        } else {
          delete this.brand;
        }
        break;
      case 'capacity':
        if (value) {
          this.capacity = value;
        } else {
          delete this.capacity;
        }
        break;
      case 'color':
        if (value) {
          this.color = value;
        } else {
          delete this.color;
        }
        break;
      case 'finish':
        if (value) {
          this.finish = value;
        } else {
          delete this.finish;
        }
        break;
      case 'heightThickness':
        if (value) {
          this.heightThickness = value;
        } else {
          delete this.heightThickness;
        }
        break;
      case 'length':
        if (value) {
          this.length = value;
        } else {
          delete this.length;
        }
        break;
      case 'model':
        if (value) {
          this.model = value;
        } else {
          delete this.model;
        }
        break;
      case 'potency':
        if (value) {
          this.potency = value;
        } else {
          delete this.potency;
        }
        break;
      case 'speed':
        if (value) {
          this.speed = value;
        } else {
          delete this.speed;
        }
        break;
      case 'voltage':
        if (value) {
          this.voltage = value;
        } else {
          delete this.voltage;
        }
        break;
      case 'width':
        if (value) {
          this.width = value;
        } else {
          delete this.width;
        }
        break;
      default:
        break;
    }
  }
}
